<template>
  <div class="incrSync">
    <div class="searchCondition">
      <div class="searchConditionItem">
        <span>任务名：</span>
        <a-input
          placeholder="请输入任务名"
          v-model.trim="taskName"
          @pressEnter="query(1)"
          allowClear
          @change="allowClearChange"
        />
      </div>
      <div class="searchConditionItem">
        <span>状态：</span>
        <a-select
          v-model="status"
          placeholder="请选择状态"
          allowClear
          @change="query(1)"
        >
          <a-select-option v-for="i in stateList" :key="i.code" :value="i.code">
            {{ i.desc }}
          </a-select-option>
        </a-select>
      </div>
      <div class="searchButton">
        <a-button type="primary" @click="query(1)" icon="search">查询</a-button>
        <a-button type="primary" @click="add" icon="plus">新增</a-button>
      </div>
    </div>
    <a-table
      :columns="tableColumns"
      :dataSource="tableDataSource"
      :pagination="tablePagination"
      :loading="loadingTable"
      size="middle"
      :rowKey="(record) => record.id"
    >
      <span slot="statusDesc" slot-scope="text, record">
        <a-tag :color="record.statusColor">
          {{ record.statusDesc }}
        </a-tag>
      </span>
      <span slot="successCount" slot-scope="text, record" style="width: 100%">
        <div :id="'myChart' + record.id" class="myChart"></div>
      </span>
      <span slot="action" slot-scope="text, record">
        <a-button type="link" @click="modifyClick(record)">修改</a-button>
        <a-divider type="vertical" />
        <a-popconfirm
          placement="right"
          okText="确认"
          cancelText="取消"
          @confirm="startTask(record)"
          :disabled="record.status == 'RUNNING'"
        >
          <template slot="title">确认是否启动任务</template>
          <a href="javascript:;" :disabled="record.status == 'RUNNING'">
            启动
          </a>
        </a-popconfirm>
        <a-divider type="vertical" />
        <a-popconfirm
          placement="right"
          okText="确认"
          cancelText="取消"
          @confirm="stopTask(record)"
          :disabled="record.status == 'STOP'"
        >
          <template slot="title">确认是否停止任务</template>
          <a
            href="javascript:;"
            style="color: #ff4d4f"
            :disabled="record.status == 'STOP'"
          >
            停止
          </a>
        </a-popconfirm>
        <a-divider type="vertical" />
        <a-dropdown>
          <a class="ant-dropdown-link" @click="(e) => e.preventDefault()">
            其它 <a-icon type="down" />
          </a>
          <a-menu slot="overlay">
            <a-menu-item>
              <a-button type="link" @click="enableSample(record)"
                >取样</a-button
              >
            </a-menu-item>
            <a-menu-item>
              <a-popconfirm
                placement="right"
                okText="确认"
                cancelText="取消"
                @confirm="deleteClick(record)"
              >
                <template slot="title">确认是否删除</template>
                <a href="javascript:;" style="color: #ff4d4f"> 删除 </a>
              </a-popconfirm>
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </span>
    </a-table>
    <!-- 新增 -->
    <a-modal
      :title="title"
      v-model="addKeyVisible"
      :maskClosable="false"
      class="action-class"
      width="500px"
    >
      <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }">
        <a-form-item label="任务名:">
          <a-input v-model="updateData.taskName" placeholder="请输入任务名" />
        </a-form-item>
        <a-form-item label="事件:">
          <a-checkbox-group v-model="updateData.event" :options="eventList">
            <span slot="label" slot-scope="{ value }">{{ value }}</span>
          </a-checkbox-group>
        </a-form-item>
        <a-form-item label="下游数据类型:">
          <a-select
            v-model="updateData.sinkType"
            placeholder="请选择下游数据类型"
            @change="getRuleList()"
          >
            <a-select-option
              v-for="(item, index) in sinkTypeList"
              :key="index"
              :value="item"
            >
              {{ item }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="规则:">
          <a-select
            v-model="ruleId"
            placeholder="请选择同步类型"
            style="width: 75%"
            :dropdownMatchSelectWidth="false"
          >
            <a-select-option
              v-for="item in ruleList"
              :key="item.id"
              :value="item.id"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
          <a-button
            type="link"
            style="margin-left: 5px"
            :disabled="!ruleId"
            @click="setRule(false)"
          >
            修改
          </a-button>
          <a-button type="link" style="margin-left: 5px" @click="setRule(true)">
            新增
          </a-button>
        </a-form-item>
        <a-form-item label="备注:">
          <a-input v-model="updateData.remark" placeholder="请输入备注" />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="addKeyVisible = false">取消</a-button>
          <a-button
            key="submit"
            type="primary"
            :loading="loading"
            @click="add_submit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>

    <!-- 子任务列表 -->
    <a-modal
      title="子任务列表"
      v-model="sampleVisible"
      :maskClosable="false"
      class="action-class"
      width="1000px"
      @cancel="sampleClose"
    >
      <a-table
        :columns="sampleColumns"
        :dataSource="sampleData"
        :pagination="false"
        size="middle"
      >
      </a-table>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="sampleClose"> 关闭 </a-button>
        </div>
      </template>
    </a-modal>

    <convert-rule-modal
      :title="title"
      :show="show"
      :ruleId="ruleId"
      :addInfo="addInfo"
      type="incr"
      @close="close"
      @submit="submit"
    ></convert-rule-modal>
  </div>
</template>
<script>
import * as api from "../lib/incrSync.js";
import { syncRulePage } from "../lib/syncRuleList";
import { generateName } from "../lib/fullSync.js";
import convertRuleModal from "./convertRuleModal.vue";
import axios from "axios";
import * as echarts from "echarts";

export default {
  name: "incrSync",
  components: { convertRuleModal },
  data() {
    return {
      title: "",
      taskName: "",
      status: undefined,
      stateList: [],
      eventList: [],
      sinkTypeList: ["MYSQL", "CLICKHOUSE"],
      groupId: "",
      addKeyVisible: false,
      updateData: {},
      loading: false,
      pageNo: 1,
      tableColumns: [
        {
          title: "编号",
          dataIndex: "id",
        },
        {
          title: "任务名",
          dataIndex: "taskName",
        },
        {
          title: "状态",
          dataIndex: "statusDesc",
          scopedSlots: { customRender: "statusDesc" },
        },
        {
          title: "同步类型",
          dataIndex: "syncType",
        },
        {
          title: "事件",
          dataIndex: "eventDesc",
        },
        {
          title: "成功数",
          dataIndex: "successCount",
          scopedSlots: { customRender: "successCount" },
          width: 220,
          align: "center",
        },
        {
          title: "创建时间",
          dataIndex: "gmtCreated",
        },
        {
          title: "备注",
          dataIndex: "remark",
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      tableDataSource: [],
      tablePagination: {},
      loadingTable: false,
      ruleId: 0,
      ruleList: [],
      show: false,
      timer: null,
      addInfo: {},
      cancelToken: axios.CancelToken.source(),
      sampleColumns: [],
      sampleData: [],
      sampleTimer: null,
      sampleVisible: false,
      sampleLoading: false,
      cancelSampleToken: axios.CancelToken.source(),
    };
  },
  mounted() {
    this.getStateList();
    this.getEventList();
  },
  methods: {
    getStateList() {
      api.taskStateList().then((res) => {
        if (res.result === 200) {
          this.stateList = res.data;
          this.query(1);
        }
      });
    },
    getEventList() {
      api.taskEventList().then((res) => {
        if (res.result === 200) {
          res.data.forEach((item) => {
            this.eventList.push({ label: item.desc, value: item.code });
          });
        }
      });
    },
    getRuleList(ruleId) {
      let data = {
        pageNo: this.pageNo,
        pageSize: 100,
        syncType: "KAFKA_2_" + this.updateData.sinkType,
      };
      syncRulePage(data).then((res) => {
        if (res.result == 200) {
          this.ruleList = res.data.records;
          this.ruleId = ruleId;
        }
      });
    },
    allowClearChange(e) {
      if (e.target.value) {
        return;
      }
      this.query(1);
    },
    // 点击查询
    query(index) {
      if (index) {
        this.pageNo = index;
      }
      let data = {
        pageNo: this.pageNo,
        pageSize: 10,
        taskName: this.taskName,
        status: this.status,
      };
      if (!this.timer) {
        this.loadingTable = true;
      }
      api
        .queryPage(data)
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            let list = res.data.records;
            if (list.length > 0) {
              list.forEach((item) => {
                this.getStateDesc(item);
                let event = [];
                let eventDesc = "";
                if ((item.event & 1) == 1) {
                  event.push(1);
                  eventDesc = "新增";
                }
                if ((item.event & 2) == 2) {
                  event.push(2);
                  eventDesc = eventDesc + (eventDesc ? "," : "") + "修改";
                }
                if ((item.event & 4) == 4) {
                  event.push(4);
                  eventDesc = eventDesc + (eventDesc ? "," : "") + "删除";
                }
                item.event = event;
                item.eventDesc = eventDesc;
              });
              this.tableDataSource = list;

              if (this.timer) {
                this.cancelToken.cancel();
                this.cancelToken = axios.CancelToken.source();
                clearInterval(this.timer);
                this.timer = null;
              }
              if (this.tableDataSource.length > 0) {
                let idList = this.tableDataSource.map((item) => {
                  return item.id;
                });

                let ids = idList.join(",");
                const { token } = this.cancelToken;
                this.getIncrSuccessCountData(ids, token);
              }
            }
            this.tablePagination = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: data.pageSize,
              current: data.pageNo,
              total: res.data.total,
              onChange: (current) => this.changePageItem(current),
            };
          }
        })
        .catch((err) => {
          this.cancelToken.cancel();
          this.cancelToken = axios.CancelToken.source();
          if (this.timer) {
            clearInterval(this.timer);
            this.timer = null;
          }
          this.loadingTable = false;
        });
    },
    // 翻页
    changePageItem(index) {
      this.query(index);
    },
    getIncrSuccessCountData(ids, token) {
      api.incrSuccessCount({ ids }, token).then((res) => {
        if (res.result == 200) {
          let runningIdList = [];
          res.data.forEach((data) => {
            this.tableDataSource.forEach((item) => {
              if (item.id == data.taskId) {
                // 获取对象的键并转换为数组
                let keys = Object.keys(data.countMap);

                // 按时间对键进行排序
                keys.sort((a, b) => {
                  let timeA = a.split(":").map(Number);
                  let timeB = b.split(":").map(Number);
                  return timeA[0] - timeB[0] || timeA[1] - timeB[1];
                });

                // 根据排序后的键生成对应的值数组
                const successCount = keys.map((key) => data.countMap[key]);
                this.$set(item, "successCount", successCount);

                if (!item.chart) {
                  this.$set(
                    item,
                    "chart",
                    echarts.init(document.getElementById(`myChart${item.id}`))
                  );
                }
                this.setOption(item, keys);
                if (!this.timer) {
                  let areAllValuesZero = successCount.some((value) => {
                    return value !== 0;
                  });
                  if (item.status == "RUNNING" || areAllValuesZero) {
                    runningIdList.push(item.id);
                  }
                }
              }
            });
          });

          if (runningIdList.length > 0) {
            let runningIds = runningIdList.join(",");
            this.timer = setInterval(() => {
              const { token } = this.cancelToken;
              this.getIncrSuccessCountData(runningIds, token);
            }, 5000);
          }
        }
      });
    },
    setOption(item, keys) {
      let options = {
        tooltip: {
          trigger: "axis",
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: keys,
          show: false,
        },
        yAxis: {
          type: "value",
          show: false,
        },
        color: "#7fd555",
        series: {
          data: item.successCount,
          type: "line",
          smooth: true, // 使折线平滑
          // 配置去掉折线上的点
          itemStyle: {
            opacity: 0, // 设置透明度为 0 使点不可见
          },
          // 配置折线样式
          lineStyle: {
            width: 2, // 设置折线宽度
          },
          areaStyle: {},
        },
      };
      item.chart.setOption(options);
    },
    getStateDesc(item) {
      switch (item.status) {
        case "STOP":
          item.statusDesc = "停止";
          item.statusColor = "red";
          break;
        case "RUNNING":
          item.statusDesc = "同步中";
          item.statusColor = "orange";
          break;
      }
    },
    // 修改
    modifyClick(val) {
      this.title = "修改";
      this.groupId = val.id;
      this.updateData = {
        taskName: val.taskName,
        remark: val.remark,
        event: val.event,
        sinkType: val.syncType.split("_2_")[1],
      };
      this.ruleId = val.syncRuleId;
      this.getRuleList(this.ruleId);
      this.addKeyVisible = true;
    },
    // 新增
    add() {
      this.title = "新增";
      this.addKeyVisible = true;
      this.ruleId = undefined;
      this.getRuleList();

      generateName().then((res) => {
        if (res.result === 200) {
          this.updateData = {
            taskName: res.data.name,
            event: [1, 2, 4],
            sinkType: this.sinkTypeList[0],
            remark: "",
          };
        }
      });
    },
    setRule(add) {
      if (add) {
        this.ruleId = undefined;
        this.addInfo = {
          sourceType: "KAFKA",
          sinkType: this.updateData.sinkType,
        };
      }
      this.show = true;
    },
    close() {
      this.show = false;
    },
    submit(ruleId) {
      this.show = false;
      if (ruleId) {
        this.getRuleList(ruleId);
      }
      if (this.title == "修改") {
        this.add_submit();
      }
    },
    // 确定新增
    add_submit() {
      let data = { ...this.updateData };
      data.syncRuleId = this.ruleId;
      data.event = data.event.reduce((acc, curr) => acc + curr, 0);
      if (!data.syncRuleId) {
        this.$message.warning("请设置规则");
        return;
      }
      data.syncType = "KAFKA_2_" + data.sinkType;
      delete data.sinkType;
      if (this.title === "新增") {
        api.addData(data).then((res) => {
          if (res.result === 200) {
            this.addKeyVisible = false;
            this.$message.success("添加成功");
            this.query();
          }
        });
      } else {
        data.id = this.groupId;
        api.editData(data).then((res) => {
          if (res.result === 200) {
            this.addKeyVisible = false;
            this.$message.success("修改成功");
            this.query();
          }
        });
      }
    },
    startTask(val) {
      let data = {
        id: val.id,
      };
      api.start(data).then((res) => {
        if (res.result === 200) {
          this.$message.success("启动成功");
          this.query();
        }
      });
    },
    stopTask(val) {
      let data = {
        id: val.id,
      };
      api.stop(data).then((res) => {
        if (res.result === 200) {
          this.$message.success("停止成功");
          this.query();
        }
      });
    },
    enableSample(val) {
      this.sampleLoading = true;
      this.sampleColumns = [
        {
          title: "类型",
          dataIndex: "typeDesc",
          fixed: "left",
          width: 50,
        },
        {
          title: "数据库",
          dataIndex: "database",
        },
        {
          title: "表名",
          dataIndex: "table",
        },
      ];
      let data = {
        id: val.id,
      };
      api.enableSample(data).then((res) => {
        if (res.result === 200) {
          this.sampleVisible = true;
          const { token } = this.cancelSampleToken;
          this.sampleTimer = setInterval(() => {
            this.getSample(data, token);
          }, 1000);
        }
      });
    },
    getSample(data, token) {
      this.sampleLoading = false;
      api
        .getSample(data, token)
        .then((res) => {
          if (res.result === 200) {
            this.sampleData = res.data;
            if (this.sampleData.length > 0) {
              this.sampleData.forEach((item) => {
                for (const key in item.data) {
                  this.$set(item, key, item.data[key]);
                }
                switch (item.type) {
                  case "insert":
                    this.$set(item, "typeDesc", "新增");
                    break;
                  case "update":
                    this.$set(item, "typeDesc", "修改");
                    break;
                  case "delete":
                    this.$set(item, "typeDesc", "删除");
                    break;
                }
              });
              for (const key in this.sampleData[0].data) {
                this.sampleColumns.push({
                  title: key,
                  dataIndex: key,
                });
              }
            }
            if (this.sampleData.length == 10) {
              clearInterval(this.sampleTimer);
              this.sampleTimer = null;
            }
          }
        })
        .catch(() => {
          this.sampleClose();
        });
    },
    sampleClose() {
      this.sampleVisible = false;
      this.cancelSampleToken.cancel();
      if (this.sampleTimer) {
        clearInterval(this.sampleTimer);
        this.sampleTimer = null;
      }
    },
    // 点击删除
    deleteClick(val) {
      let data = {
        id: val.id,
      };
      api.deleteData(data).then((res) => {
        if (res.result === 200) {
          this.$message.success("删除成功");
          this.query();
        }
      });
    },
  },
  beforeDestroy() {
    this.cancelToken.cancel();
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }
    this.tableDataSource.forEach((item) => {
      if (item.chart) {
        item.chart.dispose();
      }
    });
  },
};
</script>
<style lang="scss" scoped>
.myChart {
  width: 200px;
  height: 80px;
}
</style>
